import Main from '@/layouts/Main'

export default {
  path: "/",
  redirect: { name: "auth.login" },
  component: Main,
  children: [
    {
      path: "home",
      name: "main.home",
      component: () => import("@/views/main/NoticeBoard.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Mural de Avisos",
        icon: "mdi-clipboard-clock",
        hide: false,
      },
    },
    {
      path: "notice-home",
      name: "main.notice-home",
      component: () => import("@/views/main/Home.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Início",
        icon: "mdi-home",
        hide: false,
      },
    },
    {
      path: "news",
      name: "main.news",
      component: () => import("@/views/main/News.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Notícias",
        icon: "mdi-newspaper-variant-outline",
        hide: false,
      },
    },
    {
      path: "gallery",
      name: "main.gallery",
      component: () => import("@/views/main/Gallery.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Galerias",
        icon: "mdi-image-multiple",
        hide: false,
      },
    },
    {
      path: "photo/:slug",
      name: "main.photo",
      component: () => import("@/views/main/Photos.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Ver Fotos",
        icon: "mdi-image-multiple",
        hide: true,
      },
    },
    {
      path: "timesheets",
      name: "main.timesheets",
      component: () => import("@/views/main/Timesheets.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Espelho de ponto",
        icon: "mdi-clock-outline",
        hide: false,
      },
    },
    {
      path: "payslips",
      name: "main.payslips",
      component: () => import("@/views/main/Payslips.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Holerites",
        icon: "mdi-cash-100",
        hide: false,
      },
    },
    // {
    //   path: 'birthdays',
    //   name: 'main.birthdays',
    //   component: () => import('@/views/main/Birthdays.vue'),
    //   meta: { requiresLogin: true },
    //   props: {
    //     title: 'Aniversariantes',
    //     icon: 'mdi-gift-outline',
    //     hide: false
    //   }
    // },
    // {
    //   path: 'extension',
    //   name: 'main.extension',
    //   component: () => import('@/views/main/Extension.vue'),
    //   meta: { requiresLogin: true },
    //   props: {
    //     title: 'Lista de Ramais',
    //     icon: 'mdi-counter',
    //     hide: false
    //   }
    // },
    // {
    //   path: 'calendar',
    //   name: 'main.calendar',
    //   component: () => import('@/views/main/Calendar.vue'),
    //   meta: { requiresLogin: true },
    //   props: {
    //     title: 'Agenda',
    //     icon: 'mdi-calendar',
    //     hide: false
    //   }
    // },
    {
      path: "/read-news/:slug",
      name: "main.readnews",
      component: () => import("@/views/main/ReadNews.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Ler Notícia",
        icon: "mdi-chat-alert",
        hide: true,
      },
    },
    {
      path: "/income-report",
      name: "main.incomereport",
      component: () => import("@/views/main/IncomeReport.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "IRPF",
        icon: "mdi-currency-usd",
        hide: false,
      },
    },
    {
      path: "/vacation",
      name: "main.vacation",
      component: () => import("@/views/main/Vacation.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Férias",
        icon: "mdi-emoticon-happy-outline",
        hide: false,
      },
    },
    {
      path: "/compliance",
      name: "main.compliance",
      component: () => import("@/views/main/Compliance.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Compliance",
        icon: "mdi-account-voice",
        hide: false,
      },
    },
    {
      path: "/compliance/category/:id",
      name: "main.compliancecategory",
      component: () => import("@/views/main/ComplianceCategory.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "ComplianceCategory",
        icon: "mdi-account-voice",
        hide: true,
      },
    },
    {
      path: "/compliance/subcategory/:id",
      name: "main.compliancesubcategory",
      component: () => import("@/views/main/ComplianceSubcategory.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "ComplianceSubcategory",
        icon: "mdi-account-voice",
        hide: true,
      },
    },
    {
      path: "/read-compliance/:slug",
      name: "main.readcompliamce",
      component: () => import("@/views/main/ReadCompliance.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Ler Compliance",
        icon: "mdi-chat-alert",
        hide: true,
      },
    },
    {
      path: "/articles",
      name: "main.articles",
      component: () => import("@/views/main/Articles.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Artigos",
        icon: "mdi-account-voice",
        hide: false,
      },
    },
    {
      path: "/read-articles/:slug",
      name: "main.readcompliamce",
      component: () => import("@/views/main/ReadArticles.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Ler Artigo",
        icon: "mdi-chat-alert",
        hide: true,
      },
    },
    {
      path: "/lgpd",
      name: "main.lgpd",
      component: () => import("@/views/main/Lgpd.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "LGPD",
        icon: "mdi-account-voice",
        hide: false,
      },
    },
    {
      path: "/read-lgpd/:slug",
      name: "main.readcompliamce",
      component: () => import("@/views/main/ReadLgpd.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Ler LGPD",
        icon: "mdi-chat-alert",
        hide: true,
      },
    },
    // {
    //   path: '/ticket/',
    //   name: 'main.ticket',
    //   component: () => import('@/views/main/Ticket.vue'),
    //   meta: { requiresLogin: true },
    //   props: {
    //     title: 'Chamados',
    //     icon: 'mdi-chat-alert',
    //     hide: false
    //   }
    // },
    {
      path: "compliment",
      name: "main.compliment",
      component: () => import("@/views/main/Compliment.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Elogios",
        icon: "mdi-account-heart",
        hide: false,
      },
    },
    {
      path: "promotions",
      name: "main.promotions",
      component: () => import("@/views/main/Promotions.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Promoções",
        icon: "mdi-account-star",
        hide: false,
      },
    },
    {
      path: "/elogios",
      name: "main.complimentList",
      component: () => import("@/views/main/ListCompliment.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Elogios",
        icon: "mdi-account-heart",
        hide: true,
      },
    },
    {
      path: "/suggestions/",
      name: "main.suggestions",
      component: () => import("@/views/main/Suggestions.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Sugestões",
        icon: "mdi-bullhorn",
        hide: false,
      },
    },
    {
      path: "/vagas/",
      name: "main.jobs",
      component: () => import("@/views/main/Jobs.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Vagas Internas",
        icon: "mdi-trending-up",
        hide: false,
      },
    },

    {
      path: "/ler/:slugCategory/:slugInstitutionalText",
      name: "main.readinstitutional",
      component: () => import("@/views/main/ReadInstitutional.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Ler Texto Institucional",
        icon: "mdi-chat-alert",
        hide: true,
      },
    },
    {
      path: "/perfil",
      name: "main.perfil",
      component: () => import("@/views/main/Profile.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Perfil",
        icon: "mdi-account",
        hide: true,
      },
    },
    {
      path: "/suggestionslist/",
      name: "main.listasugestoes",
      component: () => import("@/views/main/SuggestionsList.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Lista de Sugestões",
        icon: "mdi-account",
        hide: true,
      },
    },
    {
      path: "/answerSuggestions/:id",
      name: "main.resposta",
      component: () => import("@/views/main/AnswerSuggestions.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Resposta",
        icon: "mdi-account",
        hide: true,
      },
    },
    {
      path: "/candidatar/:id",
      name: "main.applyjob",
      component: () => import("@/views/main/ApplyJob.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Candidatar-se",
        icon: "mdi-account",
        hide: true,
      },
    },
    {
      path: "/contact/",
      name: "main.contato",
      component: () => import("@/views/main/Contact.vue"),
      meta: { requiresLogin: true },
      props: {
        title: "Meu Contato",
        icon: "mdi-account",
        hide: true,
      },
    },
  ],
};
